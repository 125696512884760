import { humanDateFormat } from 'client-portal/utils/date-time';
import Super from './-resources';

export default class SiteBillingInsurance extends Super {
  humanDateFormat = humanDateFormat;

  get insuranceInfos() {
    return this.model.insuranceInfos;
  }
}
