import { A as emberA } from '@ember/array';
import Model, { attr } from '@ember-data/model';
import classic from 'ember-classic-decorator';

@classic
export default class ClientBillingOverviewModel extends Model {
  @attr() balanceDue;
  @attr() unallocatedPaymentAmount;
  @attr() invoicesCount;
  @attr() statementsCount;
  @attr() superbillsCount;
  @attr() insuranceClaimsCount;
  @attr() insuranceInfoCount;

  static fieldsFor(_context) {
    let fields = emberA();
    this.fields.forEach((_, v) => fields.pushObject(v));
    fields.removeObject('meta');
    return fields;
  }
}
