import { service } from '@ember/service';
// eslint-disable-next-line ember/no-mixins
import FastbootHelpersMixin from 'client-portal/mixins/route/fastboot-helpers';
import Route from '@ember/routing/route';

const ExtendedRoute = Route.extend(FastbootHelpersMixin);

export default class SiteBillingInsurance extends ExtendedRoute {
  @service router;
  @service currentPractice;

  beforeModel() {
    super.beforeModel(...arguments);

    if (!this.currentPractice.get('featureCpInsuranceInfo')) {
      return this.router.transitionTo('site.billing');
    }
  }
}
