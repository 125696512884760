import { buildValidations, validator } from 'ember-cp-validations';
import { fragment } from 'ember-data-model-fragments/attributes';
import { isoDateFormat } from 'client-portal/utils/date-time';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { reads } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

const Validations = buildValidations({
  memberId: [
    validator('presence', {
      presence: true,
    }),
  ],
  insuredRelation: [
    validator('presence', {
      presence: true,
    }),
  ],
  insuredFname: [
    validator('presence', {
      presence: true,
      disabled: reads('model.selfInsured'),
    }),
  ],
  insuredLname: [
    validator('presence', {
      presence: true,
      disabled: reads('model.selfInsured'),
    }),
  ],
  insuredDob: [
    validator('presence', {
      presence: true,
      disabled: reads('model.selfInsured'),
    }),
  ],
  insuredPhone: [
    validator('presence', {
      presence: true,
      disabled: reads('model.selfInsured'),
    }),
  ],
  insuredAddress: [
    validator('presence', {
      presence: true,
      disabled: reads('model.selfInsured'),
    }),
  ],
  insuredCity: [
    validator('presence', {
      presence: true,
      disabled: reads('model.selfInsured'),
    }),
  ],
  insuredState: [
    validator('presence', {
      presence: true,
      disabled: reads('model.selfInsured'),
    }),
  ],
  insuredZip: [
    validator('presence', {
      presence: true,
      disabled: reads('model.selfInsured'),
    }),
  ],
  insurancePlan: [
    validator('presence', {
      presence: true,
    }),
  ],
});

export default class InsuranceInfoModel extends Model.extend(Validations) {
  @belongsTo('client', { async: false }) client;
  @belongsTo('insurance-provider-name', { async: false, inverse: null }) insurancePlan;
  @hasMany('document', { async: false, inverse: null }) documents;
  @hasMany('insurance-claim', { async: true }) insuranceClaims;

  @attr() thisType;
  @attr() memberId;
  @attr() planId;
  @attr() groupId;
  @attr() insuredRelation;
  @attr() insuredFname;
  @attr() insuredLname;
  @attr('moment', { format: isoDateFormat }) insuredDob;
  @attr() insuredPhone;
  @attr() insuredAddress;
  @attr() insuredCity;
  @attr() insuredState;
  @attr() insuredZip;
  @attr() createdAt;
  @fragment('insurance-info/coverage-status') coverageStatus;

  @tracked isDirty = false;

  get hasRequiredFields() {
    return this.memberId && this.insuredRelation !== null && this.insurancePlan;
  }

  get validInsuranceCards() {
    return this.documents.filter(card => !card.get('isMarkedForDeletion'));
  }

  get selfInsured() {
    return this.insuredRelation === '0';
  }
}
